<template>
  <div class="analytics-usage-clinical">
    <div class="row">
      <div class="col-sm-10">
        <owl-usage-clinical-column-chart
          :is-loading="clinicalSignInsLoading"
          :series="clinicalSignInsSeries"
          :has-errors="clinicalSignInsHasErrors"
          :start="start"
          :end="end"
          :graph-interval="graphInterval"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { OwlUsageClinicalColumnChart } from 'owl-components'
import {
  FETCH_CLINICAL_SIGNINS
} from '@/store/modules/analytics/usage/constants.js'

export default {
  name: 'AnalyticsUsageClinical',
  components: {
    OwlUsageClinicalColumnChart
  },
  computed: {
    ...mapGetters({
      clinicalSignInsLoading: 'getAnalyticsUsageClinicalSignInsLoadingState',
      clinicalSignInsParams: 'getAnalyticsUsageClinicalSignInsParams',
      clinicalSignInsSeries: 'getAnalyticsUsageClinicalSignInsSeries',
      clinicalSignInsHasErrors: 'getAnalyticsUsageClinicalSignInsHasErrors',
      globalParams: 'getAnalyticsUsageGlobalParams'
    }),
    chartOptions () {
      return {}
    },
    start () {
      return this.clinicalSignInsParams.criteria.date_range.greater_than
    },
    end () {
      return this.clinicalSignInsParams.criteria.date_range.less_than
    },
    graphInterval () {
      return this.clinicalSignInsParams.criteria.graph_interval
    }
  },
  watch: {
    globalParams: {
      deep: true,
      handler () {
        this.getClinicalSignInData()
      }
    }
  },
  created () {
    this.getClinicalSignInData()
  },
  methods: {
    getClinicalSignInData () {
      this.$store.dispatch(FETCH_CLINICAL_SIGNINS)
    }
  }
}
</script>
